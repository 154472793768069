define("discourse/plugins/discourse-calendar/discourse/components/csv-uploader", ["exports", "@glimmer/component", "@ember/owner", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "truth-helpers", "discourse/lib/uppy/uppy-upload", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _owner, _didInsert, _service, _truthHelpers, _uppyUpload, _dIcon, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CsvUploader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    uppyUpload = (() => new _uppyUpload.default((0, _owner.getOwner)(this), {
      type: "csv",
      id: "discourse-post-event-csv-uploader",
      autoStartUploads: false,
      uploadUrl: this.args.uploadUrl,
      uppyReady: () => {
        this.uppyUpload.uppyWrapper.uppyInstance.on("file-added", () => {
          this.dialog.confirm({
            message: (0, _i18n.default)(`${this.args.i18nPrefix}.confirmation_message`),
            didConfirm: () => this.uppyUpload.startUpload(),
            didCancel: () => this.uppyUpload.reset()
          });
        });
      },
      uploadDone: () => {
        this.dialog.alert((0, _i18n.default)(`${this.args.i18nPrefix}.success`));
      },
      validateUploadedFilesOptions: {
        csvOnly: true
      }
    }))();
    get uploadButtonText() {
      return this.uppyUpload.uploading ? (0, _i18n.default)("uploading") : (0, _i18n.default)(`${this.args.i18nPrefix}.text`);
    }
    get uploadButtonDisabled() {
      // https://github.com/emberjs/ember.js/issues/10976#issuecomment-132417731
      return this.uppyUpload.uploading || this.uppyUpload.processing || null;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span>
          <label class="btn" disabled={{this.uploadButtonDisabled}}>
            {{icon "upload"}}&nbsp;{{this.uploadButtonText}}
            <input
              {{didInsert this.uppyUpload.setup}}
              class="hidden-upload-field"
              disabled={{this.uppyUpload.uploading}}
              type="file"
              accept=".csv"
            />
          </label>
          {{#if (or this.uppyUpload.uploading this.uppyUpload.processing)}}
            <span>{{i18n "upload_selector.uploading"}}
              {{this.uppyUpload.uploadProgress}}%</span>
          {{/if}}
        </span>
      
    */
    {
      "id": "ukDKv2cK",
      "block": "[[[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"btn\"],[15,\"disabled\",[30,0,[\"uploadButtonDisabled\"]]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"upload\"],null]],[1,\" \"],[1,[30,0,[\"uploadButtonText\"]]],[1,\"\\n        \"],[11,\"input\"],[24,0,\"hidden-upload-field\"],[16,\"disabled\",[30,0,[\"uppyUpload\",\"uploading\"]]],[24,\"accept\",\".csv\"],[24,4,\"file\"],[4,[32,1],[[30,0,[\"uppyUpload\",\"setup\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[28,[32,2],[[30,0,[\"uppyUpload\",\"uploading\"]],[30,0,[\"uppyUpload\",\"processing\"]]],null],[[[1,\"        \"],[10,1],[12],[1,[28,[32,3],[\"upload_selector.uploading\"],null]],[1,\"\\n          \"],[1,[30,0,[\"uppyUpload\",\"uploadProgress\"]]],[1,\"%\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/csv-uploader.js",
      "scope": () => [_dIcon.default, _didInsert.default, _truthHelpers.or, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CsvUploader;
});